import {
  OhsMultiUserSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';

export enum RequestType {
  Admin = 'admin',
  Attachments = 'attachments',
  Module = 'modules',
}

export enum OhsApiRequestName {
  Archive = 'archive',
  ArchiveExchange = 'exchange.archive',
  ArchiveProse = 'prose.archive',
  ArchiveRoll = 'roll.archive',
  ArchiveForm = 'form.archive',
  AssignAggregate = 'assign.status.list',
  AssignStatus = 'assign.status',
  AssignTo = 'assign.to',
  Authenticate = 'authenticate',
  AuthenticateInactive = 'authenticate.inactive',
  BulkArchive = 'bulk.archive',
  BulkDisable = 'bulk.disable',
  BulkEnable = 'bulk.enable',
  BulkMove = 'bulk.move',
  BulkDelete = 'bulk.delete',
  BulkUnarchive = 'bulk.unarchive',
  BulkSwitch = 'bulk.profileswitch',
  BulkUnoverride = 'bulk.unoverride',
  DefinitionBulkFetch = 'definition.bulk.fetch',
  Create = 'create',
  CreateOrgPreset = 'create.orgPresets',
  CreateProse = 'prose.create',
  CreateRoll = 'roll.create',
  CreateExchange = 'exchange.create',
  CreateTask = 'create.task',
  ConfidentialSet = 'confidential.set',
  ConfidentialUnSet = 'confidential.unset',
  CustomList = 'custom.list',
  CustomGetToken = 'custom.getToken',
  DefinitionFetch = 'definition.fetch',
  Delete = 'delete',
  DeleteTask = 'delete.task',
  Disable = 'disable',
  DeleteRoll = 'roll.delete',
  DeleteExchange = 'exchange.delete',
  Download = 'download',
  Edit = 'edit',
  EditLocations = 'edit.locations',
  EditProse = 'prose.edit',
  EditRoll = 'roll.edit',
  EditExchange = 'exchange.edit',
  EditTask = 'edit.task',
  Enhance = 'enhance',
  ExportTemplateList = 'template.list',
  ExportTemplateCreate = 'template.create',
  ExportTemplateDelete = 'template.delete',
  ExportTemplateEdit = 'template.edit',
  Export = 'export',
  ExportListUser = 'list.user',
  ExportListOrg = 'list.org',
  ListOrgShift = 'orgShift.list',
  OrgShift = 'orgShift.shift',
  Fetch = 'fetch',
  Index = 'index',
  ProfileSwitch = 'edit.profileswitch',
  FetchExchange = 'exchange.fetch',
  FetchRoll = 'roll.fetch',
  List = 'list',
  ListActive = 'list.active',
  ListAssigned = 'list.assigned',
  ListFor = 'list.for',
  ListRoll = 'roll.list',
  ListProse = 'prose.list',
  ListForms = 'form.list',
  ListExchange = 'exchange.list',
  ListConfiguredLocations = 'list.configuredLocations',
  ListVersions = 'list.version',
  ListIncidents = 'list.incidents',
  BoardList = 'board.list',
  BlockList = 'blocklist.fetch',
  MultiUser = 'multiuser.authorize',
  MultiUserList = 'multiuser.list',
  SignOff = 'signoff',
  SignOffStart = 'signoff.start',
  SignOffRemoveSave = 'signoff.removeSave',
  StatusRoll = 'roll.metrics',
  UnArchive = 'unarchive',
  UnArchiveExchange = 'exchange.unarchive',
  UnArchiveProse = 'prose.unarchive',
  UnArchiveRoll = 'roll.unarchive',
  UnArchiveForm = 'form.unarchive',
  UnAssignTo = 'unassign.to',
  UpVersion = 'upVersion',
  ValuesCreate = 'values.create',
  ValuesBulkFetch = 'values.bulk.fetch',
  ValuesFetch = 'values.fetch',
  Whoami = 'whoami',
  Allocate = 'allocate',
  Deallocate = 'deallocate',
  AllocationList = 'allocationlist',
  AllocatedList = 'list.allocated',
  Copylist = 'copylist',
  Copy = 'copy',
  Clone = 'clone',
  MfaList = 'mfa.list',
  MfaEnroll = 'mfa.enroll',
  MfaEnrollConfirm = 'mfa.enroll.confirm',
  MfaVerifyChallenge = 'mfa.challenge',
  MfaVerify = 'mfa.verify',
  MfaDeleteMethod = 'mfa.delete',
  MfaReset = 'mfa.reset',
  MfaCancel = 'mfa.cancel',
  AdminMfaReset = 'user.mfa.reset',
  Move = 'move',
  ListScheduled = 'list.scheduled',
  ListAvailable = 'list.available',
  Review = 'review',
  UserExists = 'user.exists',
  HRLookup = 'hrLookup',
  ContractorLookup = 'contractorLookup',
  EditConfiguration = 'edit.configuration',
  DefinitionBulkCreate = 'definition.bulk.create',

  MultiUserEdit = 'multiuser.edit',
  OrgControlFetch = 'orgControl.fetch',
  OrgControlEdit = 'orgControl.edit',
  BookmarkedList = 'list.bookmarked',
  BookmarkedListByTask = 'list.byTask',
  Set = 'set',
  GetToken = 'getToken',
  GetTokenWidgets = 'widgets.getToken',

  ImportIncident = 'import.incidents',
  ImportHR = 'import.hr',
  ImportChemical = 'import.chemicals',
  ImportContractor = 'import.contractors',
  ImportTraining = 'import.trainings',
  ImportUsers = 'import.users',
  ImportWorkplaces = 'import.workplaces',
  SiteAllocate = 'site.allocate',
  SiteAllocationList = 'site.allocationlist',
  SiteArchive = 'site.archive',
  SiteClone = 'site.clone',
  SiteCreate = 'site.create',
  SiteDeallocate = 'site.deallocate',
  SiteEdit = 'site.edit',
  SiteFetch = 'site.fetch',
  SiteList = 'site.list',
  SiteUnArchive = 'site.unarchive',
  TaskAggregate = 'taskAggregate',
  Tweets = 'tweets',
  VisitList = 'visit.list',
  VisitArrive = 'visit.arrive',
  VisitLeave = 'visit.leave',
  VisitBulkLeave = 'visit.bulk.leave',

  FormArchive = 'form.archive',
  FormCreate = 'form.create',
  FormEdit = 'form.edit',
  FormFetch = 'form.fetch',
  FormList = 'form.list',
  FormUnArchive = 'form.unarchive',
  FormSubmit = 'form.submit',

  PinList = 'visitor.pin.list',
  PinSet = 'visitor.pin.set',
  LinkedActions = 'list.linkedActions',

  CusvalDefCreate = 'definition.create',
  CusvalDefFetch = 'definition.fetch',
  CusvalDefPresetFetch = 'definition.preset',
  CusvalDefEdit = 'definition.edit',
  CusvalDefBulkClone = 'definition.bulk.clone',
  FileBoxList = 'filebox.list',
  NoticeBoardList = 'board.list',
  WorkerGroupList = 'worker.module.list',
  WorkerTaskAssignedList = 'worker.task.assigned.list',
  NoticeBoardDeallocate = 'board.deallocate',
  NoticeBoardAllocate = 'board.allocate',
  NoticeBoardAllocationList = 'board.allocationlist',
  NoticeBoardAllocatedList = 'board.list.allocated',
  NoticeBoardAchive = 'board.archive',
  NoticeBoardUnArchive = 'board.unarchive',
  NoticeBoardCreate = 'board.create',
  NoticeBoardEdit = 'board.edit',
  NoticeBoardFetch = 'board.fetch',
  SafetyPlanScheduleSearch = 'safetyplan.scheduled.list',
  InspectionScheduleSearch = 'inspection.scheduled.list',
  InspectionAvailableSearch = 'inspection.available.list',
  SsoLogin = 'authenticate.azure',
  MorphInto = 'morph',
  UnMorph = 'unmorph',
  BlockListEdit = 'blocklist.edit',
  BlockListFetch = 'blocklist.fetch',
  FetchTaskLinkedVdocs = 'fetch.taskLinkedVDocs',
  BulkFetchVdoc = 'fetch.linkedvdocs',
  FetchLinkedVdoc = 'fetch.linkedVDocs',
  SearchForAggregate = 'search.for.aggregate',

  MobileAppAdminVersionCreate = 'version.create',
  MobileAppAdminVersionEdit = 'version.edit',
  MobileAppAdminVersionUpdateMarker = 'version.edit.updatemarker',
  MobileAppAdminVersionFetch = 'version.fetch',
  MobileAppAdminVersionFetchByVersion = 'version.fetch.byversion',
  MobileAppAdminVersionList = 'version.list',
  OrgList = 'org.list',
  OrgWorkplaceList = 'org.workplace.list',
  ResetPassword = 'recovery',
  Support = 'support',
  FeedBack = 'feedback',

  XsiSetupLink = 'setup.link',

  ContractorLinkedTasks = 'list.linkedTasks',
  ContractorEditLinkage = 'edit.linkage',
  ContractorLinkedCorrespondence = 'list.correspondenceExchanges',
  LinkedCorrespondenceAll = 'list.correspondenceExchangesAll',
  LinkedCorrespondenceRoll = 'list.correspondenceRoll',

  // linkaccess
  Access = 'access',
  linkAccessFetch = 'linkaccess.fetch',
  linkAccessList = 'linkaccess.list',

  ListLinkable = 'list.linkable',
  FetchCopySource = 'fetch.copysource',
  ProseFetch = 'prose.fetch',
}

export enum OhsApiErrorCodes {
  UserNotFound = 'core.user:not_found',
  HrDuplicatesNotAllowed = 'core.module.hr:duplicates_not_allowed',
  HrLimitReached = 'core.module.hr:limit_reached',
  MfaRequire = 'core.user:mfa_require',
  MfaOtpVerify = 'core.user:mfa_error',
  UserInvalidSession = 'core.user:invalid_session',
  ChemicalInvalidImport = 'core.module.chemical:invalid_import',
  ContractorInvalidImport = 'core.module.contractor:invalid_import',
  IncidentInvalidImport = 'core.module.incident:invalid_import',
  TrainingInvalidImport = 'core.module.training:invalid_import',
  SessionExpired = 'core.user:session_expired',
  AccessDenied = 'core.user:access_denied',
  InvalidVisitLeave = 'core.module.visitor:invalid_visit_leave', // visitor already left
}
// 'Generic' success toaster message
export const requestTypeActionsMsg = (requestName: OhsApiRequestName, payload?: any): string => {
  let actionMessage: string = '';
  switch (requestName) {
    case OhsApiRequestName.Create:
    case OhsApiRequestName.CreateProse:
    case OhsApiRequestName.CreateRoll:
    case OhsApiRequestName.CreateExchange:
    case OhsApiRequestName.SiteCreate:
    case OhsApiRequestName.FormCreate:
      actionMessage = 'Successfully submitted!';
      // e.g. Safety Plan record has been successully added.
      break;
    case OhsApiRequestName.CreateTask:
      actionMessage = 'Successfully submitted!';
      break;
    case OhsApiRequestName.MultiUserEdit:
      actionMessage = '';
      break;

    case OhsApiRequestName.Edit:
    case OhsApiRequestName.EditRoll:
    case OhsApiRequestName.EditExchange:
    case OhsApiRequestName.SiteEdit:
    case OhsApiRequestName.FormEdit:
      actionMessage = 'Successfully updated!';
      // e.g. Safety Plan record has been successully added.
      break;
    case OhsApiRequestName.Delete:
    case OhsApiRequestName.BulkDelete:
    case OhsApiRequestName.DeleteRoll:
    case OhsApiRequestName.DeleteExchange:
      actionMessage = 'Successfully deleted!';
      break;
    case OhsApiRequestName.AssignTo:
      actionMessage = 'Successfully Assigned!';
      break;
    case OhsApiRequestName.UnAssignTo:
      actionMessage = 'User has been unassigned to this task.';
      break;
    case OhsApiRequestName.SignOff:
      if (payload) {
        if (payload.complete === true) {
          actionMessage = 'Successfully signed-off!';
        } else {
          actionMessage = 'Successfully saved!';
        }
      }
      break;
    case OhsApiRequestName.EditTask:
      actionMessage = 'Successfully updated!';
      break;
    case OhsApiRequestName.Copy:
      actionMessage = 'Successfully copied!';
      break;
    case OhsApiRequestName.Move:
    case OhsApiRequestName.BulkMove:
      actionMessage = 'Record has been moved to another workplace successfully';
      break;
    case OhsApiRequestName.Allocate:
    case OhsApiRequestName.SiteAllocate:
      if (payload.to.length > 1) {
        actionMessage = `Successfully allocated to (${payload.to.length}) workplaces!`;
      } else {
        actionMessage = 'Successfully allocated!';
      }
      break;
    case OhsApiRequestName.Deallocate:
    case OhsApiRequestName.SiteDeallocate:
      if (payload.to.length > 1) {
        actionMessage = `Successfully deallocated from (${payload.to.length}) workplaces!`;
      } else {
        actionMessage = 'Successfully deallocated!';
      }
      break;
    // TODO: add other request types
    default:
      actionMessage = '';
  }
  return actionMessage;
};

export const getApiRequestErrorMessage = (
  requestName: OhsApiRequestName,
  error?: ApiResponseErrorType
) => {
  if (requestName === OhsApiRequestName.Authenticate) {
    if (
      error?.code?.includes(OhsApiErrorCodes.UserInvalidSession) ||
      error?.code?.includes(OhsApiErrorCodes.MfaRequire)
    ) {
      return null;
    }
    return 'Login failed, please check your email and password.';
  }
  if (requestName === OhsApiRequestName.MultiUser) {
    if (error?.code?.includes(OhsApiErrorCodes.UserInvalidSession)) {
      return null;
    }
    if (error?.code?.includes(OhsApiErrorCodes.MfaRequire)) {
      return null;
    }
    return error?.code[0] ?? 'login failed.';
  }

  if (error?.code?.includes(OhsApiErrorCodes.HrDuplicatesNotAllowed)) {
    return 'Worker with same Employee Number is already exists';
  }
  if (error?.code?.includes(OhsApiErrorCodes.HrLimitReached)) {
    return 'Reach the max number of HR records， Please contact administrator.';
  }
  if (error?.code?.includes(OhsApiErrorCodes.MfaOtpVerify)) {
    return null;
  }
  if (error?.code?.includes(OhsApiErrorCodes.MfaRequire)) {
    return null;
  }
  if (requestName === OhsApiRequestName.ResetPassword) {
    return null;
  }

  const importsModules = [
    OhsApiRequestName.ImportChemical,
    OhsApiRequestName.ImportHR,
    OhsApiRequestName.ImportContractor,
    OhsApiRequestName.ImportIncident,
    OhsApiRequestName.ImportTraining,
  ];
  if (importsModules.includes(requestName)) {
    const errMsg = 'There are errors during importing.';
    if (
      error?.code?.includes(OhsApiErrorCodes.ChemicalInvalidImport) ||
      error?.code?.includes(OhsApiErrorCodes.TrainingInvalidImport) ||
      error?.code?.includes(OhsApiErrorCodes.ContractorInvalidImport) ||
      error?.code?.includes(OhsApiErrorCodes.IncidentInvalidImport)
    ) {
      return error.message[0] || errMsg;
    }
    return errMsg;
  }

  if (
    requestName === OhsApiRequestName.ImportUsers ||
    requestName === OhsApiRequestName.ImportWorkplaces ||
    requestName === OhsApiRequestName.CustomList
  ) {
    return null;
  }

  if (requestName === OhsApiRequestName.ContractorEditLinkage) {
    return 'Unable to update the link list.';
  }

  if (error?.code?.includes(OhsApiErrorCodes.AccessDenied)) {
    return null;
  }

  return 'The operation was failed.';
};

export const checkContainsErrorCode = (error: any, errorListCode: string) => {
  if (error && error.code?.includes(errorListCode)) {
    return true;
  }
  return false;
};

export interface ApiResponseType<T> {
  success: boolean;
  error?: ApiResponseErrorType;
  result?: T;
}

export interface ApiResponseErrorType {
  correlationId: string;
  code: ApiResponseErrorCodeType[];
  message: string[];
}

export type ApiResponseErrorCodeType =
  | 'core.user:not_found'
  | 'core.module.hr:duplicates_not_allowed'
  | 'core.module.hr:limit_reached'
  | 'core.user:mfa_require'
  | 'core.user:mfa_error'
  | 'core.user:invalid_session'
  | 'core.module.chemical:invalid_import'
  | 'core.module.training:invalid_import'
  | 'core.module.incident:invalid_import'
  | 'core.module.contractor:invalid_import'
  | 'core.user:access_denied';

export interface ApiResListsType<T> {
  items: T;
  pagination: {
    next: string;
    page: number;
    totalPages: number;
  };
}

export interface ApiResAllocatedListsType<T> {
  success: boolean;
  error?: any;
  result?: T;
}

export interface OhsApiRequestOptions {
  disableToast?: boolean;
  disableErrorToast?: boolean;
  sessionToken?: {
    [OhsSessionToken]?: string;
    [OhsMultiUserSessionToken]?: string;
  };
  linkAccessDownloadToken?: string;
}
