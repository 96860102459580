export default class OhsInspectionFilterModels {
  view1InspectionList: {
    viewPreset: 'view_1';
    archived?: boolean;
    sort: string;
  };

  view2InspectionList: {
    viewPreset: 'view_2';
    workplaces?: string[];
    archived?: boolean;
    allocatedToOrg?: boolean;
    allocatedToWorkplace?: boolean;
    notAllocated?: boolean;
    sort: string;
  };

  view3InspectionList: {
    viewPreset: 'view_3';
    archived?: boolean;
    sort: string;
    // allocatedToWorkplace?: boolean;
    // workplaceOwned?: boolean;
  };

  view1InspectionScheduledList: {
    viewPreset: 'view_1';
    archived?: boolean;
    workplaces?: string[];
    allocatedToOrg?: boolean;
    allocatedToWorkplace?: boolean;
    workplaceOwned?: boolean;
    inProgress?: boolean;
    sort: string;
  };

  view2InspectionScheduledList: {
    viewPreset: 'view_2';
    archived?: boolean;
    allocatedToWorkplace?: boolean;
    workplaceOwned?: boolean;
    sort: string;
  };

  view1InspectionHistoryList: {
    workplaces?: string[];
    sort: string;
    archived?: boolean;
  };

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
    sortScheduledColAscDescOptions: { name: string; value: string }[];
    sortHistoryOptions: { name: string; value: string }[];
  };

  dateDue?: { start: string; end: string };

  dateCompletedRange?: { dateStart: string; dateEnd: string };

  constructor() {
    this.view1InspectionList = {
      viewPreset: 'view_1',
      archived: false,
      sort: '{ "key": "title", "order": 1 }',
    };
    this.view2InspectionList = {
      viewPreset: 'view_2',
      workplaces: [],
      archived: false,
      allocatedToOrg: true,
      allocatedToWorkplace: true,
      notAllocated: true,
      sort: '{ "key": "title", "order": 1 }',
    };
    this.view3InspectionList = {
      viewPreset: 'view_3',
      archived: false,
      sort: '{ "key": "title", "order": 1 }',
    };

    this.view1InspectionScheduledList = {
      viewPreset: 'view_1',
      archived: false,
      workplaces: [],
      allocatedToOrg: true,
      allocatedToWorkplace: true,
      workplaceOwned: true,
      inProgress: false,
      sort: '{ "key": "dateDue", "order": 1 }',
    };
    this.view2InspectionScheduledList = {
      viewPreset: 'view_2',
      archived: false,
      allocatedToWorkplace: true,
      workplaceOwned: true,
      sort: '{ "key": "dateDue", "order": 1 }',
    };
    this.view1InspectionHistoryList = {
      workplaces: [],
      sort: '{ "key": "dateSignedoff", "order": -1 }',
    };
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Title: A-Z', value: '{ "key": "title", "order": 1 }' },
        { name: 'Title: Z-A', value: '{ "key": "title", "order": -1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
      ],
      sortScheduledColAscDescOptions: [
        { name: 'Date Due: Newest to Oldest', value: '{ "key": "dateDue", "order": -1 }' },
        { name: 'Date Due: Oldest to Newest', value: '{ "key": "dateDue", "order": 1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
      ],
      sortHistoryOptions: [
        { name: 'Sign Off: Newest to Oldest', value: '{ "key": "dateSignedoff", "order": -1 }' },
        { name: 'Sign Off: Oldest to Newest', value: '{ "key": "dateSignedoff", "order": 1 }' },
        { name: 'Date Created: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Date Created: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
  }
}
